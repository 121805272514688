import { useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { X as XIcon } from 'react-feather'
import { size } from 'lodash'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'

import { AlertDialog, BasicSection, MUI } from 'components'

import { formatters } from 'helpers'

import { useSnackbar } from 'hooks'

import {
  BannerConfigurationCardType,
  ReadaptedUrlsType,
  UrlDataType,
} from './bannerConfigurationCard.types'
import { BannerConfigurationSchemaType } from 'types/banner.types'

import { theme } from 'theme'
import service from 'service'

const BannerConfigurationCard = ({
  banner,
  setLoading,
}: BannerConfigurationCardType) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [urlData, setUrlData] = useState<UrlDataType>()

  const { snackbar } = useSnackbar()
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<BannerConfigurationSchemaType>()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'urls',
  })

  const urls = watch('urls')

  const hasOneField = size(fields) < 2

  const handleOpenRemoveUrlDialog = (
    index: number,
    url: Partial<ReadaptedUrlsType>
  ) => {
    if (index !== null && urls[index] && urls[index]?.originalId) {
      setUrlData({ index, ...url })
      setOpenDialog(true)
    } else if (index) remove(index)
  }
  const handleCloseRemoveUrlDialog = () => setOpenDialog(false)

  const handleAppendField = () => append({ url: '' })
  const handleRemoveUrl = async () => {
    try {
      setLoading(true)

      await service.dponet.bannerUrl.destroy({
        bannerId: banner.id,
        bannerUrlId: urlData?.originalId,
      })
      snackbar.open({
        message: 'URL removida com sucesso!',
        variant: 'success',
      })

      !!urlData?.index && remove(urlData.index)
      handleCloseRemoveUrlDialog()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BasicSection
      title="Configuração do Banner"
      extraActions={
        <Typography color="primary" fontWeight={500}>
          01/05
        </Typography>
      }
    >
      <Box p={4} bgcolor={theme.palette.custom.light} borderRadius={1}>
        <Typography color="primary" fontWeight={600} gutterBottom>
          Informação
        </Typography>
        <Typography color="primary" textAlign="justify">
          Um banner de cookies é um aviso exibido nos sites para informar e
          obter o consentimento dos usuários antes de rastrear e armazenar seus
          dados de navegação.
        </Typography>
      </Box>
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors?.name}
            helperText={errors?.name?.message}
            type="text"
            label="Nome"
            fullWidth
          />
        )}
        control={control}
        name="name"
      />
      {fields?.map((item, index) => (
        <Controller
          key={item?.id}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors?.urls?.[index]?.url}
              helperText={errors?.urls?.[index]?.url?.message}
              type="text"
              label={`${hasOneField ? '' : `${index + 1}º `}URL`}
              fullWidth
              {...(!hasOneField
                ? {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <XIcon
                              onClick={() =>
                                handleOpenRemoveUrlDialog(index, item)
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }
                : {})}
            />
          )}
          control={control}
          name={`urls.${index}.url`}
        />
      ))}
      <MUI.Box
        display="flex"
        alignItems="center"
        gap={4}
        $smDown={{ flexDirection: 'column', alignItems: 'start' }}
      >
        <Box p={4} bgcolor={theme.palette.custom.light} borderRadius={1}>
          <Typography color="primary" fontWeight={600} gutterBottom>
            Possui mais de uma URL para o mesmo site?
          </Typography>
          <Typography color="primary" textAlign="justify">
            Adicione as URLs do seu site para que o banner seja exibido em todas
            elas. Não esqueça de variações como "www" e sem "www". Exemplo:
            "https://www.site.com" e "https://site.com".
          </Typography>
        </Box>
        <MUI.Box flexShrink={0} $smDown={{ width: '100%' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAppendField}
            fullWidth
          >
            Adicionar URL
          </Button>
        </MUI.Box>
      </MUI.Box>
      <AlertDialog
        open={openDialog}
        onClose={handleCloseRemoveUrlDialog}
        severity="warning"
        variant="standard"
        content="Esta ação pode resultar na perda dos cookies associados e nos dados de escaneamento realizados anteriormente."
        dialogActions={
          <Button variant="contained" color="warning" onClick={handleRemoveUrl}>
            Remover
          </Button>
        }
      />
    </BasicSection>
  )
}

export default BannerConfigurationCard
