import {
  ChangeEvent,
  DragEvent,
  Fragment,
  MouseEvent,
  useRef,
  useState,
} from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  File as FileIcon,
  Upload as UploadIcon,
  X as XIcon,
} from 'react-feather'
import { Controller, useFormContext } from 'react-hook-form'
import { isEmpty, isNil, size } from 'lodash'
import { visuallyHidden } from '@mui/utils'
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'

import { MUI } from 'components'

import { formatters, validates } from 'helpers'

import { FileUploadInputType } from './fileUploadInput.types'

import constants from 'constants/index'
import { theme } from 'theme'

const FileUploadInput = ({
  accept = constants.validations.ALL_FILE_TYPES,
  attachmentsBgcolor = theme.palette.custom.light,
  backgroundColor,
  controllerBar = false,
  controlName,
  EndIcon = FileIcon,
  multiple = false,
  StartIcon = UploadIcon,
  subCaption = '',
  title,
  width,
  disabled = false,
  isViewFile = false,
  hasDownloadFile = false,
}: FileUploadInputType) => {
  const { arrayToFileList, isFileInList } = validates.file

  const [isDragging, setIsDragging] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()

  const selectedFile = watch(controlName)
  const isMultiple = size(selectedFile) > 1

  const disabledText = 'O envio de anexo está desabilitado'
  const isPreview =
    selectedFile instanceof File &&
    constants.validations.OPENABLE_FILE_MIME_TYPES.includes(selectedFile.type)

  const colorInput = validates.file.chooseInputColor(
    !!errors?.[controlName],
    isDragging,
    disabled
  )

  const handleClickFormControl = () => inputRef?.current?.click()

  const updateFileList = (files: FileList | null) => {
    if (isNil(files) || isEmpty(files)) return null

    const currentFiles = selectedFile ? Array.from(selectedFile) : []
    let updatedFiles

    if (multiple && controllerBar) {
      const newFiles =
        Array.from(files).filter(
          (newFile) => !isFileInList(newFile, currentFiles as File[])
        ) || []

      updatedFiles = arrayToFileList([...currentFiles, ...newFiles] as File[])
    } else if (multiple) {
      updatedFiles = files
    } else {
      updatedFiles = files[0]
    }

    setValue(controlName, updatedFiles, { shouldDirty: true })
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files
    updateFileList(files)
  }

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (disabled) return

    setIsDragging(true)
  }

  const handleDragLeave = () => setIsDragging(false)

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (disabled) return

    handleDragLeave()

    const files = event?.dataTransfer?.files
    updateFileList(files)
  }

  const handleRemoveFile = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    index: number
  ) => {
    event.stopPropagation()
    if (disabled) return

    const currentFiles = Array.from(selectedFile) || []
    currentFiles.splice(index, 1)

    const files = arrayToFileList(currentFiles as File[])

    setValue(controlName, size(files) > 0 ? files : null, { shouldDirty: true })

    if (inputRef.current) inputRef.current.value = ''
  }

  const handleDownloadFile = (file: File) => {
    const url = URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = url
    link.download = file.name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  const handleOpenFile = (file: File) => {
    if (
      constants.validations.OPENABLE_FILE_MIME_TYPES.includes(file.type) &&
      isViewFile
    ) {
      const url = URL.createObjectURL(file)
      window.open(url, '_blank')
    } else {
      handleDownloadFile(file)
    }
  }

  return (
    <Fragment>
      <Controller
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render={({ field }) => (
          <MUI.FormControl
            $display="flex"
            $flexDirection="column"
            $alignItems="center"
            $justifyContent="center"
            $border={`2px dashed ${colorInput}`}
            $borderRadius={4}
            $color={colorInput}
            $textAlign="center"
            $cursor={disabled ? 'not-allowed' : 'pointer'}
            $height={150}
            $width={width}
            $gap={2}
            $p={4}
            $backgroundColor={backgroundColor}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleClickFormControl}
            disabled={disabled}
          >
            <input
              ref={inputRef}
              type="file"
              accept={accept || '*/*'}
              aria-label="Selecionar arquivo"
              onChange={handleFileChange}
              style={visuallyHidden}
              multiple={multiple}
              disabled={disabled}
            />
            {isNil(selectedFile) ? (
              <Fragment>
                <StartIcon />
                <Typography
                  component="span"
                  fontWeight={isDragging ? 500 : 400}
                >
                  {disabled ? disabledText : title}
                </Typography>
                {accept && !disabled && (
                  <Box display="flex" flexDirection="column">
                    <Typography variant="caption">
                      Tipos de arquivos suportados:&nbsp;
                      {formatters.file.allowedTypes(accept)}
                    </Typography>
                    {subCaption && (
                      <Typography variant="caption">{subCaption}</Typography>
                    )}
                  </Box>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <Box display="flex" alignItems="center" maxWidth="90%" gap={2}>
                  <Box>
                    <EndIcon size={20} />
                  </Box>
                  <Typography noWrap>
                    {!isMultiple
                      ? multiple
                        ? selectedFile[0]?.name
                        : selectedFile?.name
                      : `${size(selectedFile)} arquivos selecionados`}
                  </Typography>
                  {!isMultiple && (
                    <Box>
                      <Typography variant="caption" noWrap width="fit-content">
                        {formatters.file.size(
                          multiple ? selectedFile[0]?.size : selectedFile?.size
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Typography variant="caption">
                  {disabled
                    ? disabledText
                    : 'Clique ou arraste para adicionar um novo arquivo'}
                </Typography>
                {!multiple && hasDownloadFile && (
                  <Tooltip
                    title={`Clique aqui para ${
                      isPreview && isViewFile ? 'visualizar' : 'baixar'
                    } o arquivo`}
                    arrow
                  >
                    <Box>
                      <Button
                        variant="outlined"
                        color={errors?.[controlName] ? 'error' : 'neutral'}
                        onClick={(event) => {
                          event.stopPropagation()
                          handleOpenFile(selectedFile)
                        }}
                      >
                        {`${
                          isPreview && isViewFile ? 'Visualizar' : 'Baixar'
                        } o arquivo`}
                      </Button>
                    </Box>
                  </Tooltip>
                )}
              </Fragment>
            )}
          </MUI.FormControl>
        )}
        control={control}
        name={controlName}
      />
      {!!errors?.[controlName] && (
        <FormHelperText error={!!errors?.[controlName]}>
          {<>{String(errors?.[controlName]?.message)}</>}
        </FormHelperText>
      )}
      {controllerBar && multiple && !isEmpty(selectedFile) && (
        <Box
          px={4}
          pt={4}
          border={`1px solid ${theme.palette.paper.border}`}
          borderRadius={2}
          color={theme.palette.neutral.main}
          mt={4}
          width={width}
          bgcolor={backgroundColor}
        >
          <PerfectScrollbar
            options={{ wheelPropagation: true, useBothWheelAxes: true }}
          >
            <Box display="flex" alignItems="center" gap={4} pb={4}>
              {Array.from(selectedFile as File[])?.map((file, index) => {
                const fileItemIsPreview =
                  file instanceof File &&
                  constants.validations.OPENABLE_FILE_MIME_TYPES.includes(
                    file.type
                  )

                return (
                  <MUI.Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    gap={2}
                    p={2}
                    borderRadius={1}
                    bgcolor={attachmentsBgcolor}
                    width="fit-content"
                    $cursor="pointer"
                    onClick={() => handleOpenFile(file)}
                  >
                    <Tooltip
                      title={`Clique aqui para ${
                        fileItemIsPreview && isViewFile
                          ? 'visualizar'
                          : 'baixar'
                      } o arquivo`}
                      placement="top"
                      arrow
                    >
                      <Box display="flex" alignItems="center" gap={2}>
                        <EndIcon size={18} />
                        <Typography noWrap>{file?.name}</Typography>
                      </Box>
                    </Tooltip>
                    {!disabled && (
                      <IconButton
                        size="small"
                        onClick={(event) => handleRemoveFile(event, index)}
                      >
                        <XIcon size={14} />
                      </IconButton>
                    )}
                  </MUI.Box>
                )
              })}
            </Box>
          </PerfectScrollbar>
        </Box>
      )}
    </Fragment>
  )
}

export default FileUploadInput
