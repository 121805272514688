import { Info as InfoIcon, Check as CheckIcon } from 'react-feather'
import { Box, Typography } from '@mui/material'

import { BasicDialog } from 'components'

import { SourceCodeDialogType } from './sourceCodeDialog.types'

import { theme } from 'theme'

const SourceCodeDialog = ({ open, onClose }: SourceCodeDialogType) => {
  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title="Como instalar no HTML (Via código fonte)"
      maxWidth="md"
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={6}
        justifyContent="center"
      >
        <Box display="flex" flexDirection="column" gap={4}>
          <Box
            p={4}
            bgcolor={theme.palette.custom.light}
            borderRadius={1}
            display="flex"
            alignItems="center"
            gap={4}
          >
            <Box>
              <InfoIcon color={theme.palette.primary.main} />
            </Box>
            <Typography color="primary" mt={1} variant="body1">
              Para inserir o código do banner, é necessário ter acesso ao
              código-fonte do seu site.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <CheckIcon color={theme.palette.primary.main} />
          <Typography color={theme.palette.neutral.main} variant="body2">
            Clique em <strong>VER CÓDIGO</strong>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <CheckIcon color={theme.palette.primary.main} />
          <Typography color={theme.palette.neutral.main} variant="body2">
            Selecione todo o código ou utilize o botão de copiar
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <CheckIcon color={theme.palette.primary.main} />
          <Typography color={theme.palette.neutral.main} variant="body2">
            Cole no final do corpo/body do site sem mais nenhuma tag para
            interferir
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <CheckIcon color={theme.palette.primary.main} />
          <Typography color={theme.palette.neutral.main} variant="body2">
            Salve as alterações
          </Typography>
        </Box>
      </Box>
    </BasicDialog>
  )
}

export default SourceCodeDialog
