import { useState } from 'react'
import { reverse } from 'named-urls'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { BasicDialog, MenuButton, MUI, StandardTableCell } from 'components'

import { formatters, validates } from 'helpers'

import { CookieBannerType } from 'types/banner.types'

import { useSnackbar } from 'hooks'

import routes from 'constants/routes'
import service from 'service'

const BannerTable = ({
  banners,
  refresh,
}: {
  banners: CookieBannerType[]
  refresh: () => void
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [selectedBannerId, setSelectedBannerId] = useState<number | null>(null)

  const navigate = useNavigate()
  const { snackbar } = useSnackbar()

  const handleConfig = (bannerId: string | number) => {
    navigate(reverse(routes.banners.settings, { bannerId }))
  }

  const handleDelete = async (bannerId: number | null) => {
    if (!bannerId) return
    try {
      await service.dpoeasy.banner.deleteBanner({ bannerId })
      snackbar.open({
        message: 'Banner de cookies deletado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      refresh()
    }
  }

  const handleCloseDeleteModal = () => setOpenDeleteModal(false)
  const handleOpenDeleteModal = (bannerId: number) => {
    setSelectedBannerId(bannerId)
    setOpenDeleteModal(true)
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StandardTableCell $initial>ID</StandardTableCell>
              <StandardTableCell>Nome</StandardTableCell>
              <StandardTableCell>URL</StandardTableCell>
              <StandardTableCell align="center">Acessos</StandardTableCell>
              <StandardTableCell>Planos</StandardTableCell>
              <StandardTableCell align="center">Status</StandardTableCell>
              <StandardTableCell align="center">Ações</StandardTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {banners?.map((banner) => {
              const urls = banner?.urls?.map((item) => item?.url).join(', ')

              return (
                <TableRow key={banner?.id}>
                  <StandardTableCell $initial>{banner?.id}</StandardTableCell>
                  <StandardTableCell width={300} $maxWidth={300}>
                    {banner?.name}
                  </StandardTableCell>
                  <StandardTableCell
                    width={400}
                    $maxWidth={400}
                    tooltipTitle={urls}
                  >
                    {banner?.urls?.map((item) => (
                      <Link
                        key={item?.id}
                        href={item?.url}
                        target="_blank"
                        underline="none"
                        maxWidth="fit-content"
                      >
                        {item?.url}
                      </Link>
                    ))}
                  </StandardTableCell>
                  <StandardTableCell align="center">
                    {formatters.numberToStringWithDots(
                      banner?.requestsCount ?? 0
                    )}
                    <> / </>
                    {formatters.numberToStringWithDots(
                      banner?.plan?.requestsLimit ?? 50000
                    )}
                  </StandardTableCell>
                  <StandardTableCell>
                    {banner?.plan?.name?.toUpperCase() ?? 'GRATUITO'}
                  </StandardTableCell>
                  <StandardTableCell align="center">
                    <MUI.Chip
                      label={validates.banner.chipStatusLabel(banner?.status)}
                      variant="filled"
                      size="small"
                      color={validates.banner.chipStatusColor(banner?.status)}
                    />
                  </StandardTableCell>
                  <StandardTableCell align="center">
                    <MenuButton id={`banner-${banner?.id}`}>
                      <MenuItem onClick={() => handleConfig(banner?.id)}>
                        Configurações
                      </MenuItem>
                      {/* <MenuItem onClick={handleUpgrade}>Fazer Upgrade</MenuItem> */}
                      <MenuItem
                        onClick={() => handleOpenDeleteModal(banner?.id)}
                      >
                        Excluir
                      </MenuItem>
                    </MenuButton>
                  </StandardTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <BasicDialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        title="Excluir banner"
        dialogActions={
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(selectedBannerId)}
          >
            Excluir
          </Button>
        }
      >
        <Typography>Tem certeza de que deseja excluir este banner?</Typography>
        <Typography color="error" fontWeight={500}>
          Esta ação não poderá ser desfeita!
        </Typography>
      </BasicDialog>
    </>
  )
}

export default BannerTable
