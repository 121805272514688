import { Fragment, useState } from 'react'
import { Tooltip, Typography, Collapse } from '@mui/material'
import { Code as CodeIcon } from 'react-feather'

import { SourceCodeDialog, CookieBannerCode } from './components'
import { BasicSection, MUI } from 'components'

import { theme } from 'theme'

import constants from 'constants/index'
import { validates } from 'helpers'

import { BannerInstallationCardProps } from './bannerInstallationCard.types'
import { useFormState } from 'react-hook-form'

const BannerInstallationCard = ({
  banner,
  compiling,
}: BannerInstallationCardProps) => {
  const [openSourceCodeDialog, setOpenSourceCodeDialog] = useState(false)
  const [showCode, setShowCode] = useState(
    banner?.build?.status === constants.banner.COMPLETED_COMPILE_STATUS_ID
  )
  const { isDirty } = useFormState()

  const handleOpenSourceDialog = () => setOpenSourceCodeDialog(true)
  const handleCloseSourceDialog = () => setOpenSourceCodeDialog(false)
  const handleToggleCode = () => setShowCode(!showCode)
  const handleOpenTagManagerDialog = () =>
    window.open(constants.banner.LINK_DOCUMENTATION_TAG_MANAGER, '_blank')

  const isCompileInProgress =
    compiling ||
    banner?.scanner?.status === constants.banner.INITIATED_SCANNER_STATUS_ID
  const scannerFailed =
    banner?.scanner?.status === constants.banner.FAILED_SCANNER_STATUS_ID
  const compileFailed =
    banner?.build?.status === constants.banner.FAILED_COMPILE_STATUS_ID

  const isButtonDisabled =
    isCompileInProgress || isDirty || scannerFailed || compileFailed

  return (
    <Fragment>
      <BasicSection
        title="Instalação do Banner"
        extraActions={
          <Typography color="primary" fontWeight={500}>
            05/05
          </Typography>
        }
      >
        <MUI.Box p={2} display="flex" flexDirection="column" gap={8}>
          <MUI.Box
            p={4}
            bgcolor={theme.palette.custom.light}
            borderRadius={1}
            display="flex"
            flexDirection="column"
            gap={6}
          >
            <Typography color="primary" textAlign="justify">
              Para instalar o banner de cookies, escolha o modo de instalação
              apropriado para o seu site: <strong>HTML</strong> ou{' '}
              <strong>Google Tag Manager</strong>. Em seguida, copie o código
              HTML abaixo e siga as instruções de instalação conforme o modo
              selecionado.
            </Typography>
          </MUI.Box>
          <MUI.Box
            display="flex"
            justifyContent="center"
            flexDirection="row"
            $mdDown={{ flexDirection: 'column', gap: 2 }}
            gap={6}
          >
            <MUI.Box $mdUp={{ minWidth: 300 }}>
              <MUI.Button
                variant="outlined"
                fullWidth
                onClick={handleOpenSourceDialog}
              >
                Como instalar com HTML
              </MUI.Button>
            </MUI.Box>
            <MUI.Box $mdUp={{ minWidth: 300 }}>
              <Tooltip
                title={validates.banner.viewCodeTooltipTitle(
                  isDirty,
                  isCompileInProgress
                )}
              >
                <span>
                  <MUI.Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleToggleCode}
                    disabled={isButtonDisabled}
                    startIcon={<CodeIcon size={18} />}
                  >
                    Ver código
                  </MUI.Button>
                </span>
              </Tooltip>
            </MUI.Box>
            <MUI.Box $mdUp={{ minWidth: 300 }}>
              <MUI.Button
                variant="outlined"
                fullWidth
                onClick={handleOpenTagManagerDialog}
              >
                Como instalar com Tag Manager
              </MUI.Button>
            </MUI.Box>
          </MUI.Box>
          <Collapse in={showCode}>
            <CookieBannerCode bannerToken={banner?.token} />
          </Collapse>
        </MUI.Box>
      </BasicSection>
      <SourceCodeDialog
        open={openSourceCodeDialog}
        onClose={handleCloseSourceDialog}
      />
    </Fragment>
  )
}

export default BannerInstallationCard
