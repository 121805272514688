import { useState } from 'react'
import { AlertTriangle, PlusCircle as PlusCircleIcon } from 'react-feather'
import { find, isEmpty } from 'lodash'
import {
  Box,
  Button,
  Hidden,
  Paper,
  TablePagination,
  Typography,
} from '@mui/material'

import {
  StandardPageStructure,
  NoData,
  PageHead,
  LoadingFeedback,
  MUI,
} from 'components'
import {
  BannerTable,
  BannerUpgradeDialog,
  NewBannerModal,
  UpgradeModal,
} from './components'

import { useFetch, usePagination } from 'hooks'

import { CookieBannerType } from 'types/banner.types'

import service from 'service'

const BannerMain = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openPlanUpgrade, setOpenPlanUpgrade] = useState(false)

  const [openUpgradeModal, setOpenUpgradeModal] = useState(false)

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const { response, loading, refresh } = useFetch(
    service.dpoeasy.banner.get,
    {
      perPage,
      page,
    },
    [perPage, page]
  )

  const banners = response?.data?.banners as CookieBannerType[]

  const requestsLimitReached = !!find(banners, { requestsLimitReached: true })
  const bannersLimitReached = !!find(banners, {
    bannersLimitReached: true,
  })

  const handleOpenModal = () =>
    bannersLimitReached ? setOpenUpgradeModal(true) : setOpenModal(true)

  const handleOpenPlanUpgrade = () => setOpenPlanUpgrade(true)

  const handleCloseModal = () => setOpenModal(false)

  const handleCloseUpgradeModal = () => setOpenUpgradeModal(false)

  return (
    <StandardPageStructure>
      <LoadingFeedback open={loading || isLoading} />
      <PageHead
        name="Meus banners"
        actionButton={
          <MUI.Button
            startIcon={<PlusCircleIcon size={18} />}
            variant="contained"
            onClick={handleOpenModal}
            $smDown={{ width: '100%' }}
          >
            Adicionar Site
          </MUI.Button>
        }
      />
      {requestsLimitReached && (
        <Paper
          component={Box}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          variant="outlined"
          sx={{ borderColor: '#A62735' }}
          my={6}
          p={6}
        >
          <Box display="flex" alignItems="center" gap={4}>
            <AlertTriangle color="#A62735" size={22} />
            <Typography color="#A62735">
              Você excedeu o limite de acessos permitido. Para continuar
              utilizando esta funcionalidade, é necessário contratar um plano
              que ofereça mais acessos.
            </Typography>
          </Box>
          <Button
            onClick={handleOpenPlanUpgrade}
            variant="contained"
            color="primary"
            size="small"
          >
            Verificar planos
          </Button>
        </Paper>
      )}
      {isEmpty(banners) ? (
        <NoData
          title="Nenhum banner foi encontrado"
          subTitle="Vincule um novo banner a algum site"
        />
      ) : (
        <Paper variant="outlined">
          <BannerTable banners={banners} refresh={refresh} />
          <TablePagination
            component="div"
            count={response?.data?.banners?.length || 0}
            onPageChange={(_, page) => handleChangePage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
            slotProps={{
              actions: {
                previousButton: { size: 'small' },
                nextButton: { size: 'small' },
              },
            }}
          />
        </Paper>
      )}
      <NewBannerModal
        open={openModal}
        onClose={handleCloseModal}
        setLoading={setIsLoading}
        refresh={refresh}
      />
      <UpgradeModal open={openUpgradeModal} onClose={handleCloseUpgradeModal} />
      <BannerUpgradeDialog
        open={openPlanUpgrade}
        setOpen={setOpenPlanUpgrade}
      />
    </StandardPageStructure>
  )
}

export default BannerMain
