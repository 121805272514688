import { Box, Typography } from '@mui/material'

import { ScannerStatusType } from './scannerStatus.types'

import validates from 'helpers/validates'

import constants from 'constants/index'

const ScannerStatus = ({
  status,
  variant = 'scanner',
  ...rest
}: ScannerStatusType) => {
  const isScanner = variant === 'scanner'
  const StatusIcon = validates.banner.scannerStatusIcon(status)
  const statusColor = validates.banner.scannerStatusColor(status)
  const statusLabel = isScanner
    ? validates.banner.scannerStatusLabel(status)
    : validates.banner.compileStatusLabel(status)

  const isInProgress =
    status ===
    (isScanner
      ? constants.banner.INITIATED_SCANNER_STATUS_ID
      : constants.banner.INITIATED_COMPILE_STATUS_ID)

  return (
    <Box display="flex" gap={2} alignItems="center" {...rest}>
      <Box display="flex" pb={0.5} className={isInProgress ? 'spin' : ''}>
        <StatusIcon size={18} color={statusColor} />
      </Box>
      <Typography
        color={statusColor}
        fontWeight={600}
        textTransform="uppercase"
      >
        {statusLabel}
      </Typography>
    </Box>
  )
}

export default ScannerStatus
