import React, { useState, useCallback, useEffect } from 'react'
import { Grid, Tab, Tabs, Typography } from '@mui/material'

import { BasicSection, MUI } from 'components'

import { validates } from 'helpers'

import { CookieBannerType, DescriptionType } from 'types/banner.types'
import { BannerCustomizationIdType } from 'constants/banner'

import constants from 'constants/index'
import { debounce } from 'lodash'

interface BannerCustomizationCardProps {
  banner: CookieBannerType
  descriptions: DescriptionType[]
  onDescriptionChange: (languageCode: string, description: string) => void
}

const BannerCustomizationCard = ({
  banner,
  descriptions,
  onDescriptionChange,
}: BannerCustomizationCardProps) => {
  const [selectedTab, setSelectedTab] = useState(
    constants.banner.COLOR_CUSTOMIZATION_CARD_ID as BannerCustomizationIdType
  )
  const [selectedLanguage, setSelectedLanguage] = useState(
    constants.language.DEFAULT_LANGUAGE
  )

  const currentDescription =
    descriptions.find((d) => d.language.code === selectedLanguage)
      ?.description || ''

  const [localDescription, setLocalDescription] = useState(currentDescription)

  useEffect(() => {
    setLocalDescription(currentDescription)
  }, [currentDescription])

  const logoPreview =
    selectedTab === constants.banner.LOGO_CUSTOMIZATION_CARD_ID

  const positionPreview =
    selectedTab === constants.banner.POSITION_CUSTOMIZATION_CARD_ID

  const BannerContent = validates.banner.bannerContent(selectedTab)
  const BannerPreview = validates.banner.bannerPreview(selectedTab)

  const debouncedDescriptionChange = useCallback(
    debounce((language, description) => {
      onDescriptionChange(language, description)
    }, 500),
    [onDescriptionChange]
  )

  const handleTabChange = (
    _: React.SyntheticEvent,
    clickedTab: BannerCustomizationIdType
  ) => setSelectedTab(clickedTab)

  return (
    <BasicSection
      title="Personalização do Banner"
      extraActions={
        <Typography color="primary" fontWeight={500}>
          02/05
        </Typography>
      }
    >
      <Tabs value={selectedTab} variant="scrollable" onChange={handleTabChange}>
        {constants.banner.CUSTOMIZATION_CARD_TABS.map((tab) => (
          <Tab key={tab.id} label={tab.tabName} value={tab.id} />
        ))}
      </Tabs>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          md={positionPreview ? 12 : 5}
          lg={positionPreview ? 12 : logoPreview ? 6 : 5}
          xl={positionPreview ? 4 : 6}
        >
          <MUI.Box
            display="flex"
            gap={4}
            height="100%"
            flexDirection="row"
            justifyContent="space-between"
            $lgDown={{ flexDirection: 'column' }}
          >
            <BannerContent
              onDescriptionChange={onDescriptionChange}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
              setLocalDescription={setLocalDescription}
              localDescription={localDescription}
              debouncedDescriptionChange={debouncedDescriptionChange}
            />
          </MUI.Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={positionPreview ? 12 : 7}
          lg={positionPreview ? 12 : logoPreview ? 6 : 7}
          xl={positionPreview ? 8 : 6}
        >
          <BannerPreview
            banner={banner}
            description={localDescription}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      </Grid>
    </BasicSection>
  )
}

export default React.memo(BannerCustomizationCard)
