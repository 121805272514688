import { Box, Typography } from '@mui/material'

import { MUI } from 'components'
import { ScannerStatus } from '../../..'

import { formatters } from 'helpers'

import { DetailsReadingCardType } from './detailsReadingCard types'

import { theme } from 'theme'

const DetailsReadingCard = ({ build }: DetailsReadingCardType) => {
  return (
    <MUI.Box
      bgcolor={theme.palette.background.default}
      border={1}
      borderColor={theme.palette.primary.main}
      borderRadius={1}
      p={4}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      gap={4}
      $mdDown={{ flexDirection: 'column' }}
    >
      <Box>
        <Typography color="primary" gutterBottom>
          Status
        </Typography>
        <ScannerStatus status={build?.status} variant="build" />
      </Box>
      <Box>
        <Typography color="primary" gutterBottom>
          Data
        </Typography>
        <Typography color="primary" fontWeight={600}>
          {formatters.date.ptBrFormatDateTime(
            build?.updatedAt,
            'Não encontrada'
          )}
        </Typography>
      </Box>
      <Box>
        <Typography color="primary" gutterBottom>
          Tipo de geração
        </Typography>
        <Typography color="primary" fontWeight={600}>
          Manual
        </Typography>
      </Box>
    </MUI.Box>
  )
}

export default DetailsReadingCard
