import { useEffect, useState, useCallback } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import { Loader as LoaderIcon } from 'react-feather'
import { size } from 'lodash'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, Grid, Tooltip, Typography } from '@mui/material'

import { DetailsReadingCard } from './components'
import { AlertDialog, BasicSection } from 'components'

import { formatters, validates } from 'helpers'

import { useSnackbar } from 'hooks'

import { BannerCompilationCardType } from './bannerCompilationCard.types'
import { BannerConfigurationSchemaType, UrlType } from 'types/banner.types'

import { theme } from 'theme'
import service from 'service'
import constants from 'constants/index'

const BannerCompilationCard = ({
  banner,
  refresh,
  compiling,
  setCompiling,
}: BannerCompilationCardType) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [scanUrl] = useState<number | undefined>(banner?.urls[0]?.id)
  const { watch } = useFormContext<BannerConfigurationSchemaType>()
  const { snackbar } = useSnackbar()
  const { isDirty } = useFormState()

  const arraysAreEqual = (arr1: UrlType[], arr2: UrlType[]) => {
    if (size(arr1) !== size(arr2)) return false

    return arr1.every((item1: UrlType, index: number) => {
      const item2 = arr2[index]
      return item1.id === item2.id && item1.url === item2.url
    })
  }

  const urls = watch('urls')

  const isCompileInProgress =
    compiling ||
    banner?.scanner?.status === constants.banner.INITIATED_SCANNER_STATUS_ID

  const isScannerCompleted =
    banner?.scanner?.status === constants.banner.COMPLETED_SCANNER_STATUS_ID

  const isScannerDisabled = !arraysAreEqual(urls as UrlType[], banner?.urls)

  const canCompile =
    isScannerCompleted &&
    !!scanUrl &&
    !isScannerDisabled &&
    !isCompileInProgress &&
    !isDirty

  const handleCompile = useCallback(async () => {
    try {
      setCompiling(true)
      await service.dpoeasy.banner.compile({
        bannerId: banner?.id,
      })
      snackbar.open({
        message:
          'Compilação iniciada com sucesso! Isso pode levar alguns minutos.',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setCompiling(false)
    }
  }, [banner?.id, refresh, setCompiling, snackbar])

  const handleOpenDialog = useCallback(() => setOpenDialog(true), [])
  const handleCloseDialog = useCallback(() => setOpenDialog(false), [])

  useEffect(() => {
    if (banner?.build?.status === constants.banner.INITIATED_COMPILE_STATUS_ID)
      handleOpenDialog()
  }, [banner?.build?.status, handleOpenDialog])

  return (
    <BasicSection
      title="Geração do código do Banner"
      extraActions={
        <Typography color="primary" fontWeight={500}>
          04/05
        </Typography>
      }
    >
      <Box p={4} bgcolor={theme.palette.custom.light} borderRadius={1}>
        <Typography color="primary" textAlign="justify">
          Clique no botão "Gerar" para iniciar a compilação do seu banner. Nosso
          sistema irá gerar o código com suas configurações de personalização e
          cookies. Ao final desta etapa, você terá acesso ao código de
          instalação do banner em seu site.
        </Typography>
      </Box>
      <DetailsReadingCard build={banner?.build} />
      {banner?.build?.status ===
        constants.banner.INITIATED_COMPILE_STATUS_ID && (
        <Alert severity="warning" variant="outlined">
          O código do seu banner está sendo gerado, este processo pode levar até
          5 minutos. Por favor, aguarde alguns instantes e atualize a página se
          necessário. Obrigado pela compreensão!
        </Alert>
      )}
      <Grid container spacing={4} alignItems="center" justifyContent="end">
        <Grid item xs={12} md={3} xl={2} zIndex={1}>
          <Tooltip
            title={validates.banner.compileTooltipTitle(
              isScannerCompleted,
              isScannerDisabled,
              isDirty,
              isCompileInProgress
            )}
          >
            <Box>
              <LoadingButton
                onClick={handleCompile}
                variant="contained"
                startIcon={
                  <LoaderIcon
                    size={18}
                    className={isCompileInProgress ? 'spin' : ''}
                  />
                }
                disabled={!canCompile}
                fullWidth
              >
                {isCompileInProgress ? 'Gerando' : 'Gerar'}
              </LoadingButton>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
      <AlertDialog
        open={openDialog}
        onClose={handleCloseDialog}
        variant="outlined"
        severity="warning"
        content="O seu banner está sendo compilado, este processo pode levar até 5
                minutos. Por favor, aguarde alguns instantes e atualize a página se
                necessário. Obrigado pela compreensão!"
      />
    </BasicSection>
  )
}

export default BannerCompilationCard
