import { Fragment } from 'react'
import { Minus as MinusIcon } from 'react-feather'

import {
  BannerCompileStatusIdType,
  BannerCustomizationIdType,
  BannerScannerStatusIdType,
  BannerStatusIdType,
} from 'constants/banner'

import { theme } from 'theme'
import constants from 'constants/index'

const bannerContent = (tabId: BannerCustomizationIdType) => {
  return constants.banner.CUSTOMIZATION_CARD_CONTENT[tabId] || Fragment
}

const bannerPreview = (tabId: BannerCustomizationIdType) => {
  return constants.banner.CUSTOMIZATION_CARD_PREVIEW[tabId] || Fragment
}

const chipStatusColor = (status?: BannerStatusIdType) => {
  return (status && constants.banner.STATUS_CHIP_COLOR[status]) || 'default'
}

const chipStatusLabel = (status?: BannerStatusIdType) => {
  return (
    (status && constants.banner.STATUS_TRANSLATION[status]) ||
    'Não configurado'
  ).toUpperCase()
}

const scannerStatusLabel = (status?: BannerScannerStatusIdType) => {
  return (
    (status && constants.banner.SCANNER_STATUS_TRANSLATION[status]) ||
    'Não escaneado'
  ).toUpperCase()
}

const scannerStatusColor = (status?: BannerScannerStatusIdType) => {
  return (
    (status && constants.banner.SCANNER_STATUS_COLOR[status]) ||
    theme.palette.neutral.main
  )
}

const scannerStatusIcon = (status?: BannerScannerStatusIdType) => {
  return (status && constants.banner.SCANNER_STATUS_ICON[status]) || MinusIcon
}

const compileStatusLabel = (status?: BannerCompileStatusIdType) => {
  return (
    (status && constants.banner.COMPILE_STATUS_TRANSLATION[status]) ||
    'Não gerado'
  ).toUpperCase()
}

const compileTooltipTitle = (
  isScannerCompleted: boolean,
  isScannerDisabled: boolean,
  isDirty: boolean,
  isCompileInProgress: boolean
) => {
  if (!isScannerCompleted) {
    return 'A compilação só pode ser feita depois que o scanner for completo.'
  }
  if (isScannerDisabled) {
    return 'Salve as alterações de "Configuração do Banner" antes de prosseguir com a geração do código.'
  }
  if (isDirty) {
    return 'Salve as alterações de "Personalização do Banner" antes de prosseguir com a geração do código.'
  }
  if (isCompileInProgress) {
    return 'Atualize a página para verificar se a geração do código foi concluída.'
  }
  return ''
}

const viewCodeTooltipTitle = (
  isDirty: boolean,
  isCompileInProgress: boolean
) => {
  if (isDirty) {
    return 'Salve as alterações de "Personalização do Banner" antes de prosseguir com a instalação do código.'
  }
  if (isCompileInProgress) {
    return 'O código do banner só pode ser mostrado após a compilação ter sido realizada com sucesso.'
  }
  return ''
}

export default {
  bannerContent,
  bannerPreview,
  chipStatusColor,
  chipStatusLabel,
  compileStatusLabel,
  scannerStatusColor,
  scannerStatusIcon,
  scannerStatusLabel,
  compileTooltipTitle,
  viewCodeTooltipTitle,
}
